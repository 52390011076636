import {Toast, Dialog} from 'vant';

var VantMessage = {
    simpleness(text) {
        Toast(text);
    },
    successMsg(text) {
        Toast.success(text);
    },
    errorMsg(text) {
        Toast.fail(text);
    },
    positionMsg(text, position) {
        Toast({
            message: text,
            position: position,
        });

    },
    loadingMsg(text) {
        return Toast.loading({
            message: text ? text : "加载中...",
            forbidClick: true,
            duration: 0
        });
    },
    confirmMsg(text) {
        return new Promise((resolve) => {
            Dialog.confirm({
                title: '提示',
                message: text,
            })
                .then(() => {
                    resolve(true)
                })
                .catch(() => {
                    resolve(false);
                });
        }).catch(() => {

        })
    },
    alertMsg(text) {
        return new Promise((resolve) => {
            Dialog.alert({
                title: '提示',
                message: text,
                theme: 'round-button',
            }).then(() => {
                resolve(true)
            })

        }).catch(() => {

        })
    },
}

export default VantMessage;



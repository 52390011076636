import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/login',
        component:()=> import("../views/login/login"),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/register',
        component:()=> import("../views/register/register"),
        meta: {
            title: '注册'
        }
    },
    {
        path: '/loading',
        component:()=> import("../views/loading/Loading"),
        meta: {
            title: '获取中...'
        }
    },
    {
        path: '/index',
        component: () => import("../views/index"),
        children:[
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/home/home'),
                meta:{
                    title:'首页'
                }
            },
            {
                path: '/authStatus',
                name: 'authStatus',
                component: () => import('@/views/auth/authStatus'),
                meta:{
                    title:'审核状态'
                }
            },
            {
                path: '/authCallback',
                name: 'authCallback',
                component: () => import('@/views/auth/authCallback'),
                meta:{
                    title:'认证'
                }

            },
            {
                path: '/authSubmit',
                name: 'authSubmit',
                component: () => import('@/views/auth/authSubmit'),
                meta:{
                    title:'提交认证'
                }
            },
            {
                path: '/myBackCard',
                name: 'myBackCard',
                component: () => import('@/views/bankCard/myBackCard'),
                meta:{
                    title:'我的银行卡'
                }
            },
            {
                path: '/addBackCard',
                name: 'addBackCard',
                component: () => import('@/views/bankCard/addBackCard'),
                meta:{
                    title:'添加银行卡'
                }
            },
            {
                path: '/identity',
                name: 'identity',
                component: () => import('@/views/identity/identity'),
                meta:{
                    title:'我的信息'
                }
            },
            {
                path: '/signatureMsg',
                name: 'signatureMsg',
                component: () => import('@/views/identity/signatureMsg'),
                meta:{
                    title:'我的签名'
                }
            },
            {
                path: '/userUpdate',
                name: 'userUpdate',
                component: () => import('@/views/home/userUpdate'),
                meta:{
                    title:'修改密码'
                }
            },

        ]
    }
]


const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode:'history',
    linkActiveClass: 'active',
    scrollBehavior:() =>{
        history.pushState(null,null,document.URL);
    }
})
//
// let path = [];
// router.beforeResolve((to, from, next) => {
//     // path.push(from.path);
//
//     // if (path.length > 0) {
//     //     for (let i = 0; i < path.length; i++) {
//     //         if (path[i] === '/' || path === 'home') {
//     //
//     //         }
//             console.log(from.path)
//     //     }
//     // }
//     // store.commit('routerPath', from.path);
//     next();
// });

//新版本记录路由导航控制台会报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
